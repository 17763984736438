import { useEffect, useRef } from 'react';

import { Dimmer, Typography } from '@hexa-ui/components2';
import { useIntl } from 'react-intl';
import {
  ActionButton,
  ActionContainer,
  Arrow,
  Container,
  ContentContainer,
} from './UpdatesModal.styles';

type UpdatesModalProps = {
  handleGotIt: () => void;
};

export const UpdatesModal = ({
  handleGotIt,
}: UpdatesModalProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const modalRef = useRef(null);

  const handleTabKeyPress = (event: KeyboardEvent) => {
    const modalElement = modalRef.current;
    const focusableButton = modalElement?.querySelector('button');
    if (event.key === 'Tab' && !!focusableButton) {
      event.preventDefault();
      if (document.activeElement !== focusableButton) {
        focusableButton.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleTabKeyPress);
    return () => {
      document.removeEventListener('keydown', handleTabKeyPress);
    };
  }, []);

  return (
    <>
      <Arrow />
      <Container
        tabIndex={-1}
        role="alertdialog"
        ref={modalRef}
        data-testid="updates-modal"
      >
        <ContentContainer>
          <Typography.Header variant="h6" style={{ margin: '0px' }}>
            {formatMessage({ id: 'UpdatesPopover.updates' })}
          </Typography.Header>
          <Typography.Header variant="h3" style={{ margin: '0px' }}>
            {formatMessage({ id: 'UpdatesPopover.title' })}
          </Typography.Header>
          <Typography.Body variant="large" style={{ margin: '16px 0px 0px' }}>
            {formatMessage({ id: 'UpdatesPopover.description' })}
          </Typography.Body>
        </ContentContainer>
        <ActionContainer>
          <Typography.Header variant="h5" style={{ margin: '0px' }}>
            {formatMessage({ id: 'UpdatesPopover.pagination' })}
          </Typography.Header>
          <ActionButton
            onClick={handleGotIt}
            tabIndex={0}
            data-testid="got-it-button"
          >
            {formatMessage({ id: 'UpdatesPopover.gotIt' })}
          </ActionButton>
        </ActionContainer>
      </Container>
      <Dimmer style={{ zIndex: 500 }} />
    </>
  );
};
