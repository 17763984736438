/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: string | null
	/**
	 * The element text on the front end with localization.
	 */
	element_label: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
	 */
	element_location_name: string | null
	/**
	 * The category of the object that anchors the button.
	 */
	element_location_type: ElementLocationType
	/**
	 * The element name on the back end.
	 */
	element_name: string | null
	/**
	 * The element type.
	 */
	element_type: ElementType
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The user email in the given context. Samples: 'user@bees.com'
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed.
	 */
	view_level: string | null
}
export interface ForgotPasswordCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface ForgotPasswordContactInformationCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
	/**
	 * The user email in the given context. Samples: 'user@bees.com'
	 */
	user_email: string | null
}
export interface ForgotPasswordFailed {
	/**
	 * Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
	 */
	failure_reason: Record<string, any>
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface ForgotPasswordOtpVerificationCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface ForgotPasswordStarted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface ForgotPasswordSubmitted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface LoginAuthenticator {
	/**
	 * Returns whether the authentication occurred via Email or via the authenticator
	 */
	authentication_method: AuthenticationMethod
}
export interface LoginAuthenticatorFailed {
	/**
	 * Returns whether the authentication occurred via Email or via the authenticator
	 */
	authentication_method: AuthenticationMethod
	/**
	 * Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
	 */
	failure_reason: Record<string, any>
}
export interface LoginFailed {
	/**
	 * Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
	 */
	failure_reason: Record<string, any>
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface LoginStarted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface LoginSubmitted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
	/**
	 * The user email in the given context. Samples: 'user@bees.com'
	 */
	user_email: string | null
}
export interface LoginSuccessful {
	/**
	 * The information if the user is federated with SSO. If yes, set TRUE. If not, set FALSE.
	 */
	is_federated: boolean | null
	/**
	 * If the user is partner or not

	 */
	is_partner: boolean | null
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
	/**
	 * This property refer to the name of the Partner

	 */
	partner_name: string | null
	/**
	 * This property refer to the type of the Partner, like 1P, 3P, 3P Pro, and others

	 */
	partner_type: string | null
}
export interface MenuNavigation {
	/**
	 * It is the BEES Hub page accessed. Sample: PIM, Category Management
	 */
	app_name: string | null
	/**
	 * It is the menu interaction of the method used in the BEES Hub. Sample: Dashboard, App Switcher
	 */
	menu_interaction: MenuInteraction
}
export interface PageViewed {
	/**
	 * It is the BEES Hub page accessed. Sample: PIM, Category Management
	 */
	app_name: string | null
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface RegistrationCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface RegistrationContactInformationCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
	/**
	 * The user email in the given context. Samples: 'user@bees.com'
	 */
	user_email: string | null
}
export interface RegistrationFailed {
	/**
	 * Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
	 */
	failure_reason: Record<string, any>
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface RegistrationOtpVerificationCompleted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface RegistrationStarted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}
export interface RegistrationSubmitted {
	/**
	 * The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
	 */
	origin_app: OriginApp
}

export enum ElementLocationType {
	Page = 'PAGE',
	Modal = 'MODAL',
	Menu = 'MENU',
	Chip = 'CHIP',
	TopBar = 'TOP_BAR',
}
export enum ElementType {
	Button = 'BUTTON',
	Tab = 'TAB',
	Card = 'CARD',
	MenuOption = 'MENU_OPTION',
	Breadcrumbs = 'BREADCRUMBS',
	Link = 'LINK',
	Toggle = 'TOGGLE',
	Hotspot = 'HOTSPOT',
	ListItem = 'LIST_ITEM',
	Other = 'OTHER',
}
export enum PageName {
	Home = 'HOME',
	Details = 'DETAILS',
	PageName = 'PAGE_NAME',
	Null = 'null',
}
export enum OriginApp {
	AdminPortal = 'Admin Portal',
	BeesGrowAdminPortal = 'BEES Grow Admin Portal',
	CompanyManagementAdminPortal = 'Company Management Admin Portal',
	MembershipAdminPortal = 'Membership Admin Portal',
	PimAdminPortal = 'PIM Admin Portal',
	Null = 'null',
}
export enum AuthenticationMethod {
	Email = 'EMAIL',
	Authenticator = 'AUTHENTICATOR',
}
export enum MenuInteraction {
	AppSwitcher = 'App Switcher',
	Dashboard = 'Dashboard',
	Null = 'null',
}

export type ViolationHandler = (
	message: Segment.TrackMessage<Record<string, any>>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * If NODE_ENV="test", this handler will throw an error. Otherwise, it will log
 * a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	if (process.env.NODE_ENV === 'test') {
		throw new Error(msg)
	}
	console.warn(msg)
}

const missingAnalyticsNodeError = new Error(`You must set an analytics-node instance:

>	const SegmentAnalytics = require('analytics-node')
>	const { setTypewriterOptions } = require('./analytics')
>
>	const analytics = new SegmentAnalytics('SEGMENT_WRITE_KEY')
>	setTypewriterOptions({
>		analytics: analytics,
>	})

For more information on analytics-node, see: https://segment.com/docs/sources/server/node/quickstart/
`)

let analytics: () => Segment.AnalyticsNode | undefined = () => {
	throw missingAnalyticsNodeError
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 */
	analytics: Segment.AnalyticsNode
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 * This function must be called with a configured analytics-node instance before firing
 * any analytics calls, or else a `missingAnalyticsNodeError` error will be thrown.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsNode} analytics - Underlying analytics instance where analytics
 * 		calls are forwarded on to.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics ? () => options.analytics : analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext<P, T extends Segment.TrackMessage<P>>(
	message: T
): T {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * A message payload for an analytics-node `.track()` call.
 * See: https://segment.com/docs/spec/track/
 *
 * @typedef TrackMessage<PropertiesType>
 * @property {string | number} [userId] - The ID for this user in your database.
 * @property {string | number} [anonymousId] - An ID to associated with the user when you don’t know who they are.
 * @property {PropertiesType} [properties] - A dictionary of properties for the event.
 * @property {Date} [timestamp] - A Javascript date object representing when the track took place. If the track
 * 		just happened, leave it out and we’ll use the server’s time. If you’re importing data from the past make
 * 		sure you to send a timestamp.
 * @template PropertiesType
 */

/**
 * @typedef ElementClicked
 * @property {string | null} app_display_name - The current name of the app where the event was triggered
 * @property {string | null} app_id - The unique id of the app where the event was triggered
 * @property {string | null} element_label - The element text on the front end with localization.
 * @property {string | null} element_location_name - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
 * @property {ElementLocationType} element_location_type - The category of the object that anchors the button.
 * @property {string | null} element_name - The element name on the back end.
 * @property {ElementType} element_type - The element type.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The user email in the given context. Samples: 'user@bees.com'
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - (IF APPLICABLE) The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed.
 */
/**
 * @typedef ForgotPasswordCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef ForgotPasswordContactInformationCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 * @property {string | null} user_email - The user email in the given context. Samples: 'user@bees.com'
 */
/**
 * @typedef ForgotPasswordFailed
 * @property {Record<string, any>} failure_reason - Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef ForgotPasswordOtpVerificationCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef ForgotPasswordStarted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef ForgotPasswordSubmitted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef LoginAuthenticator
 * @property {AuthenticationMethod} authentication_method - Returns whether the authentication occurred via Email or via the authenticator
 */
/**
 * @typedef LoginAuthenticatorFailed
 * @property {AuthenticationMethod} authentication_method - Returns whether the authentication occurred via Email or via the authenticator
 * @property {Record<string, any>} failure_reason - Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
 */
/**
 * @typedef LoginFailed
 * @property {Record<string, any>} failure_reason - Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef LoginStarted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef LoginSubmitted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 * @property {string | null} user_email - The user email in the given context. Samples: 'user@bees.com'
 */
/**
 * @typedef LoginSuccessful
 * @property {boolean | null} is_federated - The information if the user is federated with SSO. If yes, set TRUE. If not, set FALSE.
 * @property {boolean | null} is_partner - If the user is partner or not

 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 * @property {string | null} partner_name - This property refer to the name of the Partner

 * @property {string | null} partner_type - This property refer to the type of the Partner, like 1P, 3P, 3P Pro, and others

 */
/**
 * @typedef MenuNavigation
 * @property {string | null} app_name - It is the BEES Hub page accessed. Sample: PIM, Category Management
 * @property {MenuInteraction} menu_interaction - It is the menu interaction of the method used in the BEES Hub. Sample: Dashboard, App Switcher
 */
/**
 * @typedef PageViewed
 * @property {string | null} app_name - It is the BEES Hub page accessed. Sample: PIM, Category Management
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef RegistrationCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef RegistrationContactInformationCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 * @property {string | null} user_email - The user email in the given context. Samples: 'user@bees.com'
 */
/**
 * @typedef RegistrationFailed
 * @property {Record<string, any>} failure_reason - Communication Service error description for 400 and 500 status. Samples: "The email request has invalid information."
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef RegistrationOtpVerificationCompleted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef RegistrationStarted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */
/**
 * @typedef RegistrationSubmitted
 * @property {OriginApp} origin_app - The product that the user is referring to in Admin Portal. It contains permitted values. Samples: 'BEES Grow Admin Portal', 'Company Management Admin Portal', 'Membership Admin Portal'
 */

/**
 * When the user clicks on the Element.
 *
 * @param {TrackMessage<ElementClicked>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	message: Segment.TrackMessage<ElementClicked>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Element Clicked',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the Forgot Password Submission is successful, it must be fired after 'Forgot Password Submitted'
 *
 * @param {TrackMessage<ForgotPasswordCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordCompleted(
	message: Segment.TrackMessage<ForgotPasswordCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits his/her contact information
 *
 * @param {TrackMessage<ForgotPasswordContactInformationCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordContactInformationCompleted(
	message: Segment.TrackMessage<ForgotPasswordContactInformationCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password Contact Information Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the Forgot Password Submission fails and returns an error
 *
 * @param {TrackMessage<ForgotPasswordFailed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordFailed(
	message: Segment.TrackMessage<ForgotPasswordFailed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password Failed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits the verification code during the Forgot Password Journey
 *
 * @param {TrackMessage<ForgotPasswordOtpVerificationCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordOtpVerificationCompleted(
	message: Segment.TrackMessage<ForgotPasswordOtpVerificationCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password OTP Verification Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user starts the Forgot Password Journey by clicking on the 'Forgot Password' link in the Login page
 *
 * @param {TrackMessage<ForgotPasswordStarted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordStarted(
	message: Segment.TrackMessage<ForgotPasswordStarted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password Started',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits the new password in the Forgot Password form
 *
 * @param {TrackMessage<ForgotPasswordSubmitted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordSubmitted(
	message: Segment.TrackMessage<ForgotPasswordSubmitted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Forgot Password Submitted',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Identify which authentication option the user selected at BEES One Login
 *
 * @param {TrackMessage<LoginAuthenticator>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginAuthenticator(
	message: Segment.TrackMessage<LoginAuthenticator>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Authenticator',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * Identify which error occurred during login authentication
 *
 * @param {TrackMessage<LoginAuthenticatorFailed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginAuthenticatorFailed(
	message: Segment.TrackMessage<LoginAuthenticatorFailed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Authenticator Failed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the login submission fails and returns an error
 *
 * @param {TrackMessage<LoginFailed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginFailed(
	message: Segment.TrackMessage<LoginFailed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Failed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user starts the login journey by accessing the Login page
 *
 * @param {TrackMessage<LoginStarted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginStarted(
	message: Segment.TrackMessage<LoginStarted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Started',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits the login form
 *
 * @param {TrackMessage<LoginSubmitted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSubmitted(
	message: Segment.TrackMessage<LoginSubmitted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Submitted',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user login submission is successful, should be fired after 'Login Submitted'
 *
 * @param {TrackMessage<LoginSuccessful>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSuccessful(
	message: Segment.TrackMessage<LoginSuccessful>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Login Successful',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user interacts with any menu in the application
 *
 * @param {TrackMessage<MenuNavigation>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuNavigation(
	message: Segment.TrackMessage<MenuNavigation>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Menu Navigation',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * It is the page opened in the BEES Hub application.
 *
 * @param {TrackMessage<PageViewed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	message: Segment.TrackMessage<PageViewed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Page Viewed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the Registration Submission is successful, it must be fired after 'Registration Submitted'
 *
 * @param {TrackMessage<RegistrationCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationCompleted(
	message: Segment.TrackMessage<RegistrationCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user successfully submits his/her contact information
 *
 * @param {TrackMessage<RegistrationContactInformationCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationContactInformationCompleted(
	message: Segment.TrackMessage<RegistrationContactInformationCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration Contact Information Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the Registration Submission fails and returns an error, it must be fired after 'Registration Submitted'
 *
 * @param {TrackMessage<RegistrationFailed>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationFailed(
	message: Segment.TrackMessage<RegistrationFailed>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration Failed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits the verification code
 *
 * @param {TrackMessage<RegistrationOtpVerificationCompleted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationOtpVerificationCompleted(
	message: Segment.TrackMessage<RegistrationOtpVerificationCompleted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration OTP Verification Completed',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user stars the Registration Journey
 *
 * @param {TrackMessage<RegistrationStarted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationStarted(
	message: Segment.TrackMessage<RegistrationStarted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration Started',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}
/**
 * When the user submits the registration form
 *
 * @param {TrackMessage<RegistrationSubmitted>} message - The analytics properties that will be sent to Segment.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationSubmitted(
	message: Segment.TrackMessage<RegistrationSubmitted>,
	callback?: Segment.Callback
): void {
	const msg = withTypewriterContext({
		properties: {},
		...message,
		event: 'Registration Submitted',
	})

	const a = analytics()
	if (a) {
		a.track(msg, callback)
	} else {
		throw missingAnalyticsNodeError
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 * This function must be called with a configured analytics-node instance before firing
	 * any analytics calls, or else a `missingAnalyticsNodeError` error will be thrown.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsNode} analytics - Underlying analytics instance where analytics
	 * 		calls are forwarded on to.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user clicks on the Element.
	 *
	 * @param {TrackMessage<ElementClicked>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * When the Forgot Password Submission is successful, it must be fired after 'Forgot Password Submitted'
	 *
	 * @param {TrackMessage<ForgotPasswordCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordCompleted,
	/**
	 * When the user submits his/her contact information
	 *
	 * @param {TrackMessage<ForgotPasswordContactInformationCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordContactInformationCompleted,
	/**
	 * When the Forgot Password Submission fails and returns an error
	 *
	 * @param {TrackMessage<ForgotPasswordFailed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordFailed,
	/**
	 * When the user submits the verification code during the Forgot Password Journey
	 *
	 * @param {TrackMessage<ForgotPasswordOtpVerificationCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordOtpVerificationCompleted,
	/**
	 * When the user starts the Forgot Password Journey by clicking on the 'Forgot Password' link in the Login page
	 *
	 * @param {TrackMessage<ForgotPasswordStarted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordStarted,
	/**
	 * When the user submits the new password in the Forgot Password form
	 *
	 * @param {TrackMessage<ForgotPasswordSubmitted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasswordSubmitted,
	/**
	 * Identify which authentication option the user selected at BEES One Login
	 *
	 * @param {TrackMessage<LoginAuthenticator>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginAuthenticator,
	/**
	 * Identify which error occurred during login authentication
	 *
	 * @param {TrackMessage<LoginAuthenticatorFailed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginAuthenticatorFailed,
	/**
	 * When the login submission fails and returns an error
	 *
	 * @param {TrackMessage<LoginFailed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginFailed,
	/**
	 * When the user starts the login journey by accessing the Login page
	 *
	 * @param {TrackMessage<LoginStarted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginStarted,
	/**
	 * When the user submits the login form
	 *
	 * @param {TrackMessage<LoginSubmitted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginSubmitted,
	/**
	 * When the user login submission is successful, should be fired after 'Login Submitted'
	 *
	 * @param {TrackMessage<LoginSuccessful>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginSuccessful,
	/**
	 * When the user interacts with any menu in the application
	 *
	 * @param {TrackMessage<MenuNavigation>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuNavigation,
	/**
	 * It is the page opened in the BEES Hub application.
	 *
	 * @param {TrackMessage<PageViewed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * When the Registration Submission is successful, it must be fired after 'Registration Submitted'
	 *
	 * @param {TrackMessage<RegistrationCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationCompleted,
	/**
	 * When the user successfully submits his/her contact information
	 *
	 * @param {TrackMessage<RegistrationContactInformationCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationContactInformationCompleted,
	/**
	 * When the Registration Submission fails and returns an error, it must be fired after 'Registration Submitted'
	 *
	 * @param {TrackMessage<RegistrationFailed>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationFailed,
	/**
	 * When the user submits the verification code
	 *
	 * @param {TrackMessage<RegistrationOtpVerificationCompleted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationOtpVerificationCompleted,
	/**
	 * When the user stars the Registration Journey
	 *
	 * @param {TrackMessage<RegistrationStarted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationStarted,
	/**
	 * When the user submits the registration form
	 *
	 * @param {TrackMessage<RegistrationSubmitted>} message - The analytics properties that will be sent to Segment.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationSubmitted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1v38LAfHpTp7K5R8RvAZ4kvEWue`)
			const a = analytics()
			if (a) {
				a.track(
					withTypewriterContext({
						event: 'Unknown Analytics Call Fired',
						properties: {
							method,
						},
						userId: 'typewriter',
					})
				)
			}
		}
	},
})
